'use client'

import { DarkMode, LightMode } from "@mui/icons-material";
import { IconButton, useTheme } from "@mui/material";
import { ColorModeContext } from "../../../app/App";
import { useContext } from "react";

export default function ColorModeToggle() {
    const theme = useTheme();
    const colorMode = useContext(ColorModeContext);

    return (
        <IconButton onClick={colorMode.toggleColorMode}>
            { theme.palette.mode === 'light' ? <LightMode /> : <DarkMode />}
        </IconButton>
    );
}
