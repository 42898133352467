import "./DataTable.scss";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import { Add, Download, Upload } from "@mui/icons-material";
import { useEffect, useState } from "react";

export default function DataTable({
    table,
    addItemButton = false,
    exportDataButton = false,
    importDataButton = false
}: {
    table: string,
    addItemButton?: boolean,
    exportDataButton?: boolean,
    importDataButton?: boolean
}) {
    const [ schema, setSchema ] = useState<Array<GridColDef>>([]);
    const [ rows, setRows ] = useState([]);

    useEffect(() => {
        populateTable();
    }, [populateTable]);

    async function populateTable() {
        const tableSchema = (await import(`../../../schemas/${table}.json`)).default;
        setSchema(tableSchema);
        const tableRows = (await import(`../../../fakedata/${table}.json`)).default;

        // Temporary work-around to convert the provided timestamp
        // to an actual date object, which MUI-X's DataGrid wants.
        for (const row of tableRows) {
            for (const field of Object.keys(row)) {
                if (field.toLowerCase().includes("date")) {
                    row[field] = new Date(row[field]);
                }
            }
        }

        setRows(tableRows);
    }

    return (
        <div className="DataTable" key={table}>
            <div className="buttonBar">
                { addItemButton &&
                    <Button variant="contained" startIcon={<Add />}>Aggiungi</Button>
                }
                { exportDataButton &&
                    <Button variant="contained" startIcon={<Download />}>Esporta</Button>
                }
                { importDataButton &&
                    <Button variant="contained" startIcon={<Upload />}>Importa</Button>
                }
            </div>
            <DataGrid rows={rows} columns={schema} pageSizeOptions={[5, 10]} checkboxSelection
                initialState={{ pagination: { paginationModel: { page: 0, pageSize: 5 } } }} />
        </div>
    );
}
