'use client'
import fakeData from "../../../fakedata/notifications.json";
import "./Notifications.scss";
import { Close, NotificationsRounded } from "@mui/icons-material";
import { Divider, Drawer, IconButton, Paper, Typography } from "@mui/material";
import { useState } from "react";
import ColorModeToggle from "../ColorModeToggle/ColorModeToggle";

function isNavigationKey(event: React.KeyboardEvent): boolean {
    return event.key === "Tab" || event.key === "Shift"
}

export default function Notifications() {
    const [ isOpen, setIsOpen ] = useState(false);

    const toggleDrawer = (open: boolean) =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (event.type === "keydown" && isNavigationKey(event as React.KeyboardEvent)) {
                return;
            }

            setIsOpen(open);
        }

    const dateFormatter = new Intl.DateTimeFormat("it-IT", { dateStyle: "medium", timeStyle: "short" });

    return (
        <>
            <IconButton onClick={toggleDrawer(true)}>
                <NotificationsRounded />
            </IconButton>
            <Drawer anchor="right" open={isOpen} onClose={toggleDrawer(false)} className="Notifications">
                <Paper className="hiddenPanel">
                    <div className="header">
                        <IconButton onClick={toggleDrawer(false)}><Close /></IconButton>
                        <Typography className="title" fontSize={"24px"} textAlign={"center"} display={"flex"}>Notifiche</Typography>
                        <ColorModeToggle />
                    </div>
                    <Divider sx={{ marginBottom: "16px" }}/>
                    <div className="content">
                        {
                            fakeData.map(notification => 
                                <div key={`${notification.timestamp}-container`}>
                                    <div className="notificationContainer" key={notification.timestamp}>
                                        <Typography key={`${notification.timestamp}-content`}>{notification.content}</Typography>
                                        <Typography key={`${notification.timestamp}-timestamp`} fontSize={"12px"}
                                            textAlign={"right"} sx={{ marginTop: "2px" }}>
                                                {dateFormatter.format(new Date(notification.timestamp))}
                                        </Typography>
                                    </div>
                                    <Divider key={`${notification.timestamp}-divider`} sx={{ marginBlock: "8px" }}/>
                                </div>
                            )
                        }
                    </div>
                </Paper>
            </Drawer>
        </>
    );
}