import "./PageLabelBar.scss";
import { Paper, Typography } from "@mui/material";

export default function PageLabelBar({
    title
}: {
    title: string
}) {
    return (
        <Paper className="PageLabelBar">
            <Typography fontSize={"24px"} fontWeight={"600"}>{title}</Typography>
        </Paper>
    );
}