import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import Certificati from './app/certificati/Certificati';
import Corsi from './app/corsi/Corsi';
import Database from './app/database/Database';
import Risorse from './app/risorse/Risorse';
import Skills from './app/skills/Skills';
import Team from './app/team/Team';
import Utenti from './app/utenti/Utenti';
import Dashboard from './app/dashboard/Dashboard';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

interface Route {
    path: string,
    element: JSX.Element,
    children?: Array<Route>
}

function addLayoutToRoutes(routes: Array<Route>, layout: JSX.Element): Array<Route> {
    return routes.map(route => {
        return {
            path: route.path,
            element: layout,
            children: [
                route
            ]
        }
    });
}

const routes = [
    {
        path: "/",
        element: <Dashboard />
    },
    {
        path: "/corsi",
        element: <Corsi />
    },
    {
        path: "/certificati",
        element: <Certificati />
    },
    {
        path: "/skills",
        element: <Skills />
    },
    {
        path: "/team",
        element: <Team />
    },
    {
        path: "/utenti",
        element: <Utenti />
    },
    {
        path: "/risorse",
        element: <Risorse />
    },
    {
        path: "/database",
        element: <Database />
    }
];

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter(addLayoutToRoutes(routes, <App />));

root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
