import "./HeaderMain.scss";
import ColorModeToggle from "../ColorModeToggle/ColorModeToggle";
import { Box, Paper } from "@mui/material";
import Logo from "../Logo/Logo";
import Notifications from "../Notifications/Notifications";

export default function HeaderMain() {
    return (
        <div className="HeaderMain">
            <Paper className="bar">
                <Box className="left">
                    <Logo width={80} height={80} />
                </Box>
                <Box className="right">
                    <Notifications />
                    <ColorModeToggle />
                </Box>
            </Paper>
        </div>
    );
}