import "./SidebarMain.scss";
import { IconProps, Paper, Stack, SvgIconTypeMap } from "@mui/material";
import { Badge, Description, Home, Lightbulb, List, People, Storage, WorkspacePremium } from "@mui/icons-material";
import { ComponentType } from "react";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import NavigationLink from "../NavigationLink/NavigationLink";

function getComponentFromIcon(icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>): ComponentType<IconProps> {
    return icon as ComponentType<IconProps>;
}

export default function SidebarMain() {
    return (
        <div className="SidebarMain">
            <Paper className="bar">
                <Stack direction={"column"} className="navigation">
                    {/* Various components that will conditionally render based on your roles */}
                    <NavigationLink path="/" text="Dashboard" Icon={getComponentFromIcon(Home)} />
                    <NavigationLink path="/corsi" text="Corsi" Icon={getComponentFromIcon(List)} />
                    <NavigationLink path="/certificati" text="Certificati" Icon={getComponentFromIcon(Description)} />
                    <NavigationLink path="/skills" text="Skills" Icon={getComponentFromIcon(Lightbulb)} />
                    <NavigationLink path="/team" text="Team" Icon={getComponentFromIcon(People)} />
                    <NavigationLink path="/utenti" text="Utenti" Icon={getComponentFromIcon(Badge)} />
                    <NavigationLink path="/risorse" text="Risorse" Icon={getComponentFromIcon(WorkspacePremium)} />
                    <NavigationLink path="/database" text="Database" Icon={getComponentFromIcon(Storage)} />
                </Stack>
            </Paper>
        </div>
    );
}