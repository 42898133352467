import { useState } from "react";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import DataTable from "../../common/DataTable/DataTable";

export default function DataTableController() {
    const [ table, setTable ] = useState("corso");

    function handleSelection(event: SelectChangeEvent) {
        setTable(event.target.value as string);
    }

    return (
        <>
            <FormControl sx={{ marginBottom: "16px" }}>
                <InputLabel id="table-selector-label">Tabella</InputLabel>
                <Select label="Tabella" labelId="table-selector-label" value={table} onChange={handleSelection}>
                    <MenuItem value={"corso"} selected>Corsi</MenuItem>
                    <MenuItem value={"certificato"}>Certificati</MenuItem>
                </Select>
            </FormControl>
            <DataTable table={table} key={table} addItemButton exportDataButton importDataButton></DataTable>
        </>
    );
}