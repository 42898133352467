import './App.scss';
import { createContext, useMemo, useState } from 'react';
import { ThemeProvider } from '@emotion/react';
import { CssBaseline, Stack, createTheme } from '@mui/material';
import HeaderMain from '../components/header/HeaderMain/HeaderMain';
import SidebarMain from '../components/sidebar/SidebarMain/SidebarMain';
import { Outlet } from 'react-router-dom';

export const ColorModeContext = createContext({ toggleColorMode: () => {} });

function App() {

    return (
        <div>
            <HeaderMain />
            <Stack direction={"row"}>
                <SidebarMain />
                <Outlet />
            </Stack>
        </div>
    );
}

export default function ToggleColorMode() {
    const previousMode = localStorage.getItem("mode");
    const [mode, setMode] = useState<'light' | 'dark'>(previousMode as ('light' | 'dark') || 'light');
    const colorMode = useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
            }
        }),
        [],
    );
  
    const theme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode
                }
            }),
        [mode],
    );

    localStorage.setItem("mode", mode);
  
    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <App />
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
}
